<template>
  <div class="announcement">
    <!-- alertMsg -->
    <v-alert :type="type" v-if="type">
      {{ alertMsg }}
    </v-alert>

    <!-- delete dialog box -->
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Confirmation
        </v-card-title>

        <v-card-text>
          <br />
          Are you sure you want to delete this announcement:
          <b>{{ delAnnouncement.name }}</b
          >?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="doDelAnnouncement()">
            Yes
          </v-btn>
          <v-btn color="primary" text @click="deleteDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- add Announcement dialog box -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Add Announcement
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="addAnnouncement.name"
            label="Name"
          ></v-text-field>
          <v-text-field
            v-model="addAnnouncement.description"
            label="Description"
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-select
            v-model="addAnnouncement.type"
            :items="AnnouncementTypeList"
            item-value="value"
            item-text="text"
            label="Announcement Type"
          >
          </v-select>
          <v-card-text>
            <div style="margin-top: 16px">
              Start Time:
            </div>
            <v-datetime-picker v-model="addAnnouncement.startTime">
              <template slot="dateIcon">
                <v-icon>Date</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>Time</v-icon>
              </template>
            </v-datetime-picker>

            <div style="margin-top: 16px">
              End Time:
            </div>
            <v-datetime-picker v-model="addAnnouncement.endTime">
              <template slot="dateIcon">
                <v-icon>Date</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>Time</v-icon>
              </template>
            </v-datetime-picker>
          </v-card-text>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doAddAnnouncement()"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit dialog box -->
    <v-dialog v-model="editDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit Announcement
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="editAnnouncement.name"
            label="Name"
          ></v-text-field>
          <v-text-field
            v-model="editAnnouncement.description"
            label="Description"
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-select
            v-model="editAnnouncement.type"
            :items="AnnouncementTypeList"
            item-value="value"
            item-text="text"
            label="Announcement Type"
          >
          </v-select>
          <v-card-text>
            <div style="margin-top: 16px">
              Start Time:
            </div>
            <v-datetime-picker v-model="editAnnouncement.startTime">
              <template slot="dateIcon">
                <v-icon>Date</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>Time</v-icon>
              </template>
            </v-datetime-picker>

            <div style="margin-top: 16px">
              End Time:
            </div>
            <v-datetime-picker v-model="editAnnouncement.endTime">
              <template slot="dateIcon">
                <v-icon>Date</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>Time</v-icon>
              </template>
            </v-datetime-picker>
          </v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditAnnouncement()"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- search box -->
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          @keyup.enter.native="searchType()"
          v-model="nameSearch"
          label="ʕ•́ᴥ•̀ʔっ♡¯\_(ツ)_/¯"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-btn
          @click="searchType()"
          @keyup.enter.native="searchType()"
          large
          elevation
          color="primary"
        >
          Search
        </v-btn>
      </v-col>
    </v-row>

    <!-- floating button [for add] -->
    <v-btn
      fab
      absolute
      class="plusButton"
      right
      bottom
      color="red accent-2"
      @click="dialog = !dialog"
    >
      <v-icon color="#fff">mdi-plus</v-icon>
    </v-btn>

    <!-- pagination table -->
    <v-data-table
      :headers="headers"
      :items="announcement"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.id }}</td>
          <td>{{ row.item.name }}</td>
          <td>{{ row.item.description }}</td>
          <td>
            {{ new Date(row.item.startTime).toISOString().substr(0, 10) }}
          </td>
          <td>{{ new Date(row.item.endTime).toISOString().substr(0, 10) }}</td>
          <td>{{ row.item.type }}</td>

          <!-- tbl row edit btn -->
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="primary"
              @click="editButtonClick(row.item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
          <!-- tbl row del btn -->
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="red"
              @click="delButtonClick(row.item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import DatetimePicker from "vuetify-datetime-picker";

import postAxios from "../../../services/axios-post";
import putAxios from "../../../services/axios-put";
import getAxios from "../../../services/axios-get";
import deleteAxios from "../../../services/axios-delete";

Vue.use(DatetimePicker);
Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      AnnouncementTypeList: [
        { text: "EXCLUSIVE", value: "EXCLUSIVE" },
        { text: "LIVE AUCTION", value: "LIVE_AUCTION" },
      ],
      type: null,
      alertMsg: null,
      pageCount: 0,
      imgFile: null,
      editImgFile: null,
      imgUrl: null,
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      addAnnouncement: {
        name: null,
        description: null,
        startTime: null,
        endTime: null,
        type: null,
      },
      editAnnouncement: {
        id: "",
        name: null,
        description: null,
        startTime: null,
        endTime: null,
        type: null,
      },
      delAnnouncement: {
        id: "",
        name: "",
      },
      nameSearch: "",
      total: 0,
      dialog: false,
      editDialog: false,
      deleteDialog: false,
      token: "",
      headers: [
        { text: "Id", value: "id" },
        { text: "Announcment", value: "name" },
        { text: "Description", value: "description" },
        { text: "Start Time", value: "startTime" },
        { text: "End Time", value: "endTime" },
        { text: "Type", value: "type" },
        { text: "Edit" },
        { text: "Delete" },
      ],
      announcement: [],
    };
  },
  mounted() {
    this.fetchCredentials();
    this.fetchAnnouncement();
  },
  methods: {
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchAnnouncement();
    },
    delButtonClick(item) {
      this.delAnnouncement.id = item.id;
      this.delAnnouncement.name = item.name;
      this.deleteDialog = true;
    },
    editButtonClick(item) {
      this.editAnnouncement.id = item.id;
      this.editAnnouncement.code = item.code;
      this.editAnnouncement.name = item.name;
      this.editAnnouncement.description = item.description;
      this.editAnnouncement.startTime = new Date(item.startTime);
      this.editAnnouncement.endTime = new Date(item.endTime);
      this.editAnnouncement.type = item.type;
      this.editDialog = true;
    },
    doDelAnnouncement() {
      const self = this;
      const param = {
        announcementId: self.delAnnouncement.id,
      };
      deleteAxios(
        `${process.env.VUE_APP_SERVER_URL}/actionSystem/delAnnouncement`,
        param,
        self.token
      )
        .then(res => {
          if (res.data) {
            self.deleteDialog = false;
            self.fetchAnnouncement();
            self.showAlert("success", "Announcement Deleted Successfully!");
          }
        })
        .catch(e => {
          self.showAlert("error", e);
        });
    },
    async doEditAnnouncement() {
      const self = this;
      try {
        const params = {
          id: self.editAnnouncement.id,
          name: self.editAnnouncement.name,
          description: self.editAnnouncement.description,
          startTime: self.editAnnouncement.startTime,
          endTime: self.editAnnouncement.endTime,
          type: self.editAnnouncement.type,
        };
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/actionSystem/updAnnouncement`,
          params,
          self.token
        )
          .then(res => {
            if (res.data) {
              self.editDialog = false;
              self.fetchAnnouncement();
              self.showAlert("success", "Announcement Edited Successfully!");
            }
          })
          .catch(e => {
            self.showAlert("error", e);
          });
      } catch (e) {
        self.showAlert("error", e);
        console.error(e);
      }
    },
    async doAddAnnouncement() {
      const self = this;
      try {
        const params = {
          name: self.addAnnouncement.name,
          description: self.addAnnouncement.description,
          startTime: self.addAnnouncement.startTime,
          endTime: self.addAnnouncement.endTime,
          type: self.addAnnouncement.type,
        };
        console.log(params);
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/actionSystem/addAnnouncement`,
          params,
          self.token
        )
          .then(res => {
            if (res.data) {
              self.dialog = false;
              self.fetchAnnouncement();
              self.showAlert("success", "New Announcement Added!");
            }
          })
          .catch(e => {
            self.showAlert("error", e);
          });
      } catch (e) {
        console.error(e);
      }
    },
    chooseImage() {
      this.addAnnouncement.logoName = this.imgFile.name;
      this.imgUrl = URL.createObjectURL(this.imgFile);
    },
    searchType() {
      this.fetchAnnouncement();
    },
    handlePageChange(newPage) {
      this.pagination.page = newPage;
      this.fetchCars();
    },
    handlePageSizeChange(newPageSize) {
      this.pagination.limit = newPageSize;
      const newPage = this.total / newPageSize;
      if (this.pagination.page > newPage) {
        this.pagination.page = Math.ceil(newPage);
      }
      this.fetchCars();
    },
    fetchAnnouncement() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        announcmentType: self.nameSearch,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/actionSystem/announcement`,
        params,
        self.token
      )
        .then(res => {
          if (res.data) {
            self.announcement = res.data.data.content;
            self.pagination.noOfPage = res.data.data.totalPages;
          }
        })
        .catch(function(error) {
          self.showAlert("error", error);
        });
    },
    fetchCredentials() {
      var self = this;
      const token = localStorage.getItem("jwt");
      if (token) {
        self.token = token;
      } else {
        self.showAlert("error", "unauthorized..");
      }
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
  },
};
</script>
<style>
.plusButton {
  margin: 50px;
}
</style>
